<template>
    <WidgetWrapper :widget="widget">
        <Calendar defaultType="multiMonthYear" />
    </WidgetWrapper>
</template>

<script>
import WidgetWrapper from '../WidgetWrapper.vue'
import Calendar from '@apps/Calendar/Widget.vue'
export default {
    props: {
        widget: {
            type: Object,
            required: true
        }
    },
    components: {
        WidgetWrapper,
        Calendar
    },
}
</script>